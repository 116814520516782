import React, { useState, useRef } from "react";
import { sendContactForm } from "../../Services/contactusapi";
import ReCAPTCHA from "react-google-recaptcha";
import { Slide } from "react-awesome-reveal";
import { useSessionId } from "../../hooks/useSessionsId";

function ContactMapForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [issueError, setIssueError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [msgcounter, setMsgcounter] = useState(250);
  const [messageCountError, setMessageCountError] = useState(false);

  const recaptchaRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const isssueRef = useRef(null);
  const messageRef = useRef(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("1");
  const [message, setMessage] = useState("");

  const { sessionId, loading } = useSessionId();

  const [apiError, setApiError] = useState(false);
  const handleName = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleIssue = (e) => {
    e.preventDefault();
    setIssue(e.target.value);
  };
  const handleMessage = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
    setMsgcounter(250 - e.target.value.length);
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    if (email === "" || email.trim() === "") {
      setEmailError(true);
      setSuccessMessage(false);
    } else {
      setEmailError(false);
    }

    if (name === "" || name.trim() === "") {
      setNameError(true);
      setSuccessMessage(false);
    } else {
      setNameError(false);
    }

    if (issue === "1") {
      setIssueError(true);
      setSuccessMessage(false);
    } else {
      setIssueError(false);
    }

    if (message === "" || message.trim() === "") {
      setMessageError(true);
      setSuccessMessage(false);
    } else {
      setMessageError(false);
    }
    if (msgcounter < 0) {
      setMessageCountError(true);
      setSuccessMessage(false);
    } else {
      setMessageCountError(false);
    }

    if (recaptchaValue === "") {
      setCaptchaError(true);
      setSuccessMessage(false);
    } else {
      setCaptchaError(false);
    }

    if (
      email !== "" &&
      name !== "" &&
      issue !== "1" &&
      message !== "" &&
      recaptchaValue !== "" &&
      email.trim() !== "" &&
      name.trim() !== "" &&
      message.trim() !== "" &&
      !(msgcounter < 0)
    ) {
      /*bodydata*/
      let bodyFormData = new FormData();

      bodyFormData.append("Subject", `turnon.tv - Contact Form - ${issue}\n`);
      bodyFormData.append(
        "Description",
        `\n Name: ${name} \n Email: ${email} \n Reason: ${issue} \n Message: ${message}`
      );
      bodyFormData.append("SuppliedEmail", email);
      bodyFormData.append("SuppliedName", name);
      bodyFormData.append("Origin", "Web Contact Form");
      bodyFormData.append("RecordTypeId", "0124v000001Ng0uAAC");
      bodyFormData.append("Type", "Customer Trouble Ticket");
      bodyFormData.append("Product__c", "Domain Registry");
      if (!loading) {
        
        sendContactForm(bodyFormData, sessionId, function (response) {
          if (response.status === true) {
            setSuccessMessage(true);
            setEmailError(false);
            setName("");
            setEmail("");
            setIssue("1");
            setMessage("");
            setApiError(false);
            nameRef.current.value = "";
            emailRef.current.value = "";
            isssueRef.current.value = "1";
            messageRef.current.value = "";
            setMsgcounter(250);
            recaptchaRef.current?.reset();
          } else {
            setSuccessMessage(false);
            setApiError(true);
          }
        });
      }
    }
  };

  return (
    <>
      <div className="mt-md-4 section-row">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-md-6 mb-4 mb-md-0">
              <Slide cascade direction="right">
                <div className="contact-from-box">
                  {successMessage === true ? (
                    <div
                      className="alert alert-success mb-3"
                      id="UploadSuccess"
                    >
                      Thanks for contacting us! We will get in touch with you
                      shortly.
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="contact-from">
                    <form
                      action="#"
                      onSubmit={(e) => {
                        onsubmitHandler(e);
                      }}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          className={
                            name
                              ? "form-control filled"
                              : "form-control unfilled"
                          }
                          name="name"
                          onChange={(e) => {
                            handleName(e);
                          }}
                          ref={nameRef}
                        />
                        <label>
                          Name <span className="required">*</span>
                        </label>
                        {nameError === true ? (
                          <small className="text-danger">
                            Please enter name
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className={
                            email
                              ? "form-control filled"
                              : "form-control unfilled"
                          }
                          name="email"
                          onChange={(e) => {
                            handleEmail(e);
                          }}
                          ref={emailRef}
                        />
                        <label>
                          Email <span className="required">*</span>
                        </label>
                        {emailError === true ? (
                          <small className="text-danger">
                            Please enter valid email
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <div className="custom-select">
                          <select
                            className={
                              "form-control form-select " +
                              (issue === "1" ? "unfilled" : "filled")
                            }
                            aria-label="Default select example"
                            name="issue"
                            onChange={(e) => {
                              handleIssue(e);
                            }}
                            ref={isssueRef}
                          >
                            <option value="1"></option>

                            <option value="Trusted Registrar Partner Inquiry">
                              Trusted Registrar Partner Inquiry
                            </option>
                            <option value="Press/Media Inquiry">
                              Press/Media Inquiry
                            </option>
                            <option value="Registration Inquiry">
                              Registration Inquiry
                            </option>
                            <option value="Sales Inquiry">Sales Inquiry</option>
                            <option value="Other Inquiry">Other Inquiry</option>
                          </select>
                          <label>
                            Nature of Inquiry{" "}
                            <span className="required">*</span>
                          </label>
                        </div>
                        {issueError === true ? (
                          <small className="text-danger">
                            Please select nature of inquiry
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className="form-group"
                        style={{ position: `relative` }}
                      >
                        <textarea
                          className={
                            message
                              ? "form-control filled"
                              : "form-control unfilled"
                          }
                          name="message"
                          maxLength="250"
                          onChange={(e) => {
                            handleMessage(e);
                          }}
                          ref={messageRef}
                        ></textarea>
                        <label>
                          Message <span className="required">*</span>
                        </label>
                        {messageError === true ? (
                          <small className="text-danger">
                            Please enter message
                          </small>
                        ) : (
                          ""
                        )}
                        {messageCountError === true ? (
                          <small className="text-danger">
                            Only accept 250 characters.
                          </small>
                        ) : (
                          ""
                        )}
                        <div
                          style={{
                            color: `gray`,
                            right: `8px`,
                            top: `240px`,
                            position: `absolute`,
                          }}
                        >
                          {msgcounter}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="recaptcha-box">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                            ref={recaptchaRef}
                          />
                        </div>
                        {captchaError === true ? (
                          <small className="text-danger">
                            Please verify that you are not a robot
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                        >
                          <span>Submit</span>
                        </button>
                        {apiError === true ? (
                          <small className="text-danger">
                            Something went wrong!
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </Slide>
            </div>
            <div className="col-md-6">
              <Slide cascade direction="left">
                <div className="contact-map-box">
                  <div className="contact-address-box font-l">
                    <div className="contact-address-top d-none d-md-block">
                      <h1 className="h2">GET IN TOUCH</h1>
                      <p>
                        Use this form to send us a note, ask questions, or get
                        in touch.
                      </p>
                    </div>
                    <div className="contact-address-middle">
                      <h5>ABUSE POINT OF CONTACT </h5>
                      <p>
                        To report abuse in regard to the .TV Registry, please
                        use the form at
                        <a
                          href="https://domainabuse.registry.godaddy/s/"
                          target="blank"
                        >
                          {" "}
                          https://domainabuse.registry.godaddy.
                        </a>
                      </p>
                    </div>
                    <div className="contact-address-bottom">
                      <address>
                        Registry Abuse Investigations <br />
                        100 S. Mill Avenue <br />
                        Suite 1600 <br />
                        Tempe, AZ 85281 USA
                      </address>
                    </div>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactMapForm;
