import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getSessionId } from "../Services/sessionAPI";

export const useSessionId = () => {
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(true);
  const cookieName = "sessionData";
  const cookieExpiryHours = 24; // Expiry duration in hours

  useEffect(() => {
    const existingSessionData = Cookies.get(cookieName);

    if (existingSessionData) {
      const { sessionId: storedSessionId, expiry } =
        JSON.parse(existingSessionData);
      if (Date.now() < expiry) {
        setSessionId(storedSessionId);
        setLoading(false);
        return;
      }
    }

    // Fetch a new session ID
    (async () => {
      try {
        const newSessionData = await getSessionId();
        if (newSessionData) {
          const { session_value, time_stamp } = newSessionData;

          // Parse the time_stamp and calculate expiry
          const expiryTime =
            new Date(time_stamp).getTime() +
            23 * 60 * 60 * 1000 + // Adding hours
            55 * 60 * 1000; // Adding minutes

          const sessionData = {
            sessionId: session_value,
            expiry: expiryTime,
          };
          Cookies.set(cookieName, JSON.stringify(sessionData), {
            expires: cookieExpiryHours / 24, // Convert hours to days for cookies
          });
          setSessionId(session_value);
        }
      } catch (error) {
        console.error("Failed to get session ID", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { sessionId, loading };
};
