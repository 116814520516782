import React, { useEffect, useState } from "react";
import { getAllAccreditedRegistrars } from "../../Services/tvAPI";

import DataTable from "react-data-table-component";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import { Reveal, Fade, Roll, Zoom } from "react-awesome-reveal";
import { useSessionId } from "../../hooks/useSessionsId";

import he from "he";

function AccreditedRegistrarsContents() {
  const [flagforLoader, setFlagforLoader] = useState(0);
  const [accreditedRegistrars, setAccreditedRegistrars] = useState([]);
  const [filteredaccreditedRegistrars, setFilteredaccreditedRegistrars] =
    useState([]);
  const [search, setSearch] = useState("");

  const { sessionId, loading } = useSessionId();

  const STORY_HEADERS = [
    {
      name: "Sort by Name",
      selector: (row) => row.registrars_name,
      sortable: true,
      sortFunction: (a, b) =>
        compareIgnoreCase(a.registrars_name, b.registrars_name),
    },
    {
      name: "Sort by Country",
      selector: (row) => row.registrars_country,
      sortable: true,
    },
    {
      name: "registrars_url",
      selector: (row) => row.registrars_url,
      omit: true,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const accreditedRegistrars = async () => {
      let data = await getAllAccreditedRegistrars(sessionId);
      const accData = [];
      if (data.status) {
        data.data.map((tld) => {
          let t = {
            registrars_id: tld.iana_id,
            registrars_name: he.decode(tld.icann_name),
            registrars_country: tld.country_name,
            registrars_url: tld.homepage_url,
          };
          accData.push(t);
          return accData;
        });
      }

      setAccreditedRegistrars(accData);
      setFilteredaccreditedRegistrars(accData);
      setFlagforLoader(1);
    };
    if (!loading) {
      accreditedRegistrars();
    }
  }, [sessionId, loading]);

  const onRowClicked = (row, event) => {
    let url = row.registrars_url;
    if (url === "") {
      event.preventDefault();
    } else {
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    const result = accreditedRegistrars.filter((accreditedRegistrars) => {
      return accreditedRegistrars.registrars_name
        .toLowerCase()
        .match(search.toLowerCase());
    });
    setFilteredaccreditedRegistrars(result);
  }, [search]);

  function compareIgnoreCase(a, b) {
    let r1 = a.toLowerCase();
    let r2 = b.toLowerCase();
    if (r1 < r2) {
      return -1;
    }
    if (r1 > r2) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      {flagforLoader === 1 ? (
        <Fade>
          <div className="section-row">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="card px-3 px-md-0 accredited-registrars-list">
                    <DataTable
                      columns={STORY_HEADERS}
                      data={filteredaccreditedRegistrars}
                      pagination
                      //defaultSortFieldId={1}
                      paginationPerPage={20}
                      paginationRowsPerPageOptions={[20, 50, 100]}
                      onRowClicked={onRowClicked}
                      highlightOnHover={true}
                      pointerOnHover={true}
                      defaultSortAsc={false}
                      subHeader={true}
                      subHeaderComponent={
                        <input
                          type="text"
                          placeholder="Enter Registrars Name..."
                          className="form-control"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <div className="mb-4">
          <VolumeupdownAnimation />
        </div>
      )}
    </>
  );
}

export default AccreditedRegistrarsContents;
