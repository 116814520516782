import axios from "axios";
import { useMemo, useEffect, useState } from "react";

export const getAllAccreditedRegistrars = async (sessionValue) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL_NEW}api/getAccreditedRegistrars?site_id=${process.env.REACT_APP_SITE_KEY}&session_value=${sessionValue}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllChannelPartners API", error);
  }
};

export const getSuggestedDomain = async (domain) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/search/getSuggestedDomainList.php?domain=${domain}&tld=tv`
    );
    return response;
  } catch (error) {
    console.log("errror while calling getSuggestedDomain API", error);
  }
};

export const getAllFeatureChannelPartners = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}cms/channel-partner/featured_channel_partners.php?site_key=${process.env.REACT_APP_SITE_KEY}&ip=127.0.0.1`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllFeatureChannelPartners API", error);
  }
};

export const getAllDrawerChannelPartners = async (tld_id) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}cms/channel-partner/all_channel_partners.php?site_key=${process.env.REACT_APP_SITE_KEY}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllDrawerChannelPartners API", error);
  }
};

export const getAvailabilityStatus = async (domain) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SEARCHDOMAINAPI_URL}/cms/hub/whoisapi.php?domain=${domain}&tld=tv&whois_server=whois.nic.tv`
    );

    return response;
  } catch (error) {
    console.log("errror while calling getAvailabilityStatus API", error);
  }
};

export const useIsInViewport = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export const getTrackSearchAPI = async (
  domain,
  tldId,
  pageRef,
  utmSource,
  utmCampaign,
  utmMedium,
  utmTerm,
  availability
) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/track/search/?s=${process.env.REACT_APP_SITE_KEY}&tld_id=${tldId}&availability=${availability}&t=${domain}&pageRef=${pageRef}&utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_medium=${utmMedium}&utm_term=${utmTerm}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getTrackSearch API", error);
  }
};
