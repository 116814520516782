export const getSessionId = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL_NEW}api/getSession?site_id=${process.env.REACT_APP_SITE_KEY}`
    );
    const sessionData = await response.json();
    return sessionData;
  } catch (error) {
    console.log("Error while calling getSession API ", error);
  }
};
