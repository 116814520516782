import axios from "axios";

export const sendContactForm = (data, sessionId, callback) => {
  const URL = `${process.env.REACT_APP_API_BASE_URL_NEW}api/salesForce?site_id=${process.env.REACT_APP_SITE_KEY}&session_value=${sessionId}`;
  
  axios({
    method: "POST",
    headers: { "Content-Type": "Multipart/form-data" },
    url: URL,
    data: data,
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
};
